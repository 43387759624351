<script lang="ts">
import type { ComponentPublicInstance } from 'vue'
import { defineComponent, ref } from 'vue'

import Logo from '@/shared/assets/svg/logo/dg.svg'

export type PreloaderPublicInstance = ComponentPublicInstance & {
  visible: boolean
}

export default defineComponent({
  name: 'Preloader',
  components: {
    Logo
  },
  setup (props, { expose }) {
    const visible = ref(true)

    expose({ visible })

    return { visible }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <Transition>
      <div v-if="visible" id="app-loading">
        <Logo />
      </div>
    </Transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: fixed;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: color('white');
  inset: 0;

  svg {
    height: rem(40px);
  }
}
</style>
