/**
 * First file imported by the app
 *
 * This should NOT contain ANY dependency
 * except the ones necessary to show the preloader
 * so be careful
 */
import type { ComponentPublicInstance } from 'vue'
import { createApp } from 'vue'

import Preloader from './Preloader.vue'

export type PreloaderPublicInstance = ComponentPublicInstance<typeof Preloader>

const preloaderApp = createApp(Preloader)

if (!('__PRERENDER_INJECTED' in window)) {
  // horrible hack (TM)
  window.preloaderInstance = preloaderApp.mount('#app-loading-container') as PreloaderPublicInstance
}

if (window.parent !== window) {
  const embedHostURL = new URL(document.referrer)
  const ext = embedHostURL.host.substring(embedHostURL.host.lastIndexOf('.'))
  const domain = embedHostURL.host.replace(ext, '').replace('www.', '').replaceAll('.', '-')
  const official = ['dolcegabbana', 'thelevelgroup'].some(key => domain.indexOf(key) !== -1)
  document.documentElement.classList.add('embedded')
  document.documentElement.classList.add(`embedded-${domain}`)
  const [_, country] = window.location.pathname.match(/[a-z]{2}-([a-z]{2})/) || [] // eslint-disable-line
  window.embedHost = {
    country,
    official,
    id: domain,
    url: embedHostURL
  }
}

import('./main.ts')
  // .then(main => main)
  .catch(e => console.error(e))
